import * as React from 'react'

export function useClickOutside(fn, ref) {
  React.useEffect(() => {
    function handleClickOutside() {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        fn()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref, fn])
}
