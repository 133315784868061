import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import { motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as locks from '@rushplay/compliance/locks'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Icons from './icons'
import * as Analytics from './analytics'
import * as Player from './player'
import * as Configuration from './configuration'
import { Badge } from './badge'
import { Balance } from './balance'
import { Button } from './button'
import { LoyaltyProgramMenuProgressItem } from './loyalty-program-menu-progress-item'
import { MenuItem } from './menu-item'
import { MenuNotificationBadge } from './menu-notification-badge'
import { MyAccountMenuItem } from './my-account-menu-item'
import { PromotionsMenuItem } from './promotions-menu-item'
import { ScrollLock } from './scroll-lock'
import { VipSegmentIcon } from './vip-segment-icon'
import { useMenuQueries } from './use-menu-queries'
import { usePendingTransactions } from './pending-transactions'
import { useRaffleCampaigns } from './raffle-page/use-fetch-raffle-campaigns'
import { useServerConfiguration } from './server-configuration'

const Logotype = styled('div', {
  shouldForwardProp: Common.noneOf(['authenticated']),
})`
  ${props =>
    css({
      width: [props.authenticated ? '26px' : '145px', null, '140px'],
      height: ['30px', null, '48px'],
      backgroundImage: [
        props.authenticated
          ? 'url(/images/logo-small-authenticated.svg)'
          : 'url(/images/logo-small.svg)',
        null,
        'url(/images/logo.svg)',
      ],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    })};
`

const Wrapper = styled.header`
  ${css({
    backgroundColor: 'nav',
    position: 'sticky',
    top: '0px',
    left: '0px',
    height: [null, null, 'var(--window-inner-height, 100vh)'],
    width: ['100%', null, '200px'],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 'high',
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const Menu = styled(motion.div)`
  ${css({
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: [null, null, 'space-between'],
    height: ['calc(var(--window-inner-height, 100vh) - 46px)', null, 'auto'],
    overflowY: ['auto', null, 'initial'],
  })};

  -webkit-overflow-scrolling: touch;

  // These styles are only to override the animation styles from small screen
  ${t.mediaQuery.md`
    display: inline-flex !important;
    transform: translate(0) !important;
    opacity: 1 !important;
  `}

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const LoyaltyLink = styled(ReactRouter.Link)`
  display: flex;
  flex: 1;
`

const VipItemContent = styled('div', {
  shouldForwardProp: Common.noneOf(['authenticated', 'image']),
})`
  position: relative;
  flex: 1;

  ${props =>
    css({
      '&::after': props.authenticated && {
        content: '""',
        display: ['none', null, 'block'],
        position: 'absolute',
        width: '25px',
        height: '25px',
        top: '-2px',
        right: '0px',
        background: `url(${props.image})`,
        backgroundSize: '25px auto',
        backgroundRepeat: 'no-repeat',
      },
    })}
`

const variants = {
  open: {
    y: '0%',
    opacity: 1,
    transition: {
      x: { type: 'spring', stiffness: 200, damping: 25 },
      default: {
        duration: 0.2,
      },
    },
    display: 'inline-flex',
  },
  closed: {
    y: '-100%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
}

export function MainMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const { authenticated } = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()

  const {
    depositQuery,
    globalMomentsQuery,
    loginQuery,
    loyaltyQuery,
    personalMomentsQuery,
    registerQuery,
    withdrawalQuery,
  } = useMenuQueries()

  const [returningPlayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )
  const { locale, country } = useServerConfiguration()

  const dispatch = ReactRedux.useDispatch()
  const vipLevel = ReactRedux.useSelector(state =>
    Player.getVipLevel(state.player)
  )
  const locked = ReactRedux.useSelector(
    state => locks.isInitialized(state.locks) && locks.hasLocks(state.locks)
  )
  const mahjongEnabled = ReactRedux.useSelector(state =>
    Configuration.isMahjongEnabled(state.configuration)
  )
  const horseRacingEnabled = ReactRedux.useSelector(state =>
    Configuration.isHorseRacingEnabled(state.configuration)
  )
  const hasPlayerSeenHorseRacing = ReactRedux.useSelector(state =>
    Player.hasPlayerSeenHorseRacing(state.player)
  )

  const isRaffleEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleEnabled(state.configuration)
  )

  const isRaffleLinkEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleLinkEnabled(state.configuration)
  )

  const translate = Herz.I18n.useTranslate(
    () => [`vip.perks.table.level.${Constants.ConvertedTiers[vipLevel]}.image`],
    [vipLevel]
  )

  const { isRunning } = useRaffleCampaigns()

  const { pendingTransactions } = usePendingTransactions()

  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <Wrapper data-testid="main-menu">
      {isMenuOpen && !Constants.isDesktop && <ScrollLock />}
      {/* Topbar */}
      <Common.Box
        color="g-text"
        py={[0, null, 1]}
        pl={[0, null, '0px']}
        pr={[0, null, 1]}
        backgroundColor="nav"
        position="sticky"
        top="0px"
        left="0px"
        zIndex="high"
        display="flex"
        justifyContent={['space-between', null, 'stretch']}
        alignItems={['center', null, 'stretch']}
        flexDirection={['row', null, 'column']}
        flexShrink="0"
      >
        <Common.Box display="flex" justifyContent="center" pl={[null, null, 1]}>
          <ReactRouter.Link to="/" data-testid="main-menu-logotype">
            <Logotype title="Casino.me" authenticated={authenticated} />
          </ReactRouter.Link>
        </Common.Box>
        <Common.Flex>
          <Common.Box
            pt={[null, null, 2]}
            flexGrow="1"
            flexDirection={['row-reverse', null, 'column']}
            display="flex"
          >
            {authenticated && (
              <ReactRouter.Link
                to={`?${depositQuery}`}
                data-testid="main-menu-balance"
              >
                <Balance />
              </ReactRouter.Link>
            )}
            {!authenticated && returningPlayer && (
              <React.Fragment>
                <Common.Space pl={[null, null, 1]} pb={[null, null, 0]}>
                  <ReactRouter.Link
                    to={`?${loginQuery}`}
                    data-testid="main-menu-login"
                  >
                    <Button stretch variant="primary" fontSize={[1, null, 3]}>
                      {translate('main-menu.login')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Space>
                {country.enabled && (
                  <Common.Box
                    display={['none', null, 'block']}
                    pl={[null, null, 1]}
                  >
                    <ReactRouter.Link
                      to={`?${registerQuery}`}
                      data-testid="main-menu-register"
                    >
                      <Button
                        stretch
                        variant="primary-outlined"
                        fontSize={[1, null, 3]}
                      >
                        {translate('main-menu.register')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Box>
                )}
              </React.Fragment>
            )}
            {!authenticated && !returningPlayer && (
              <React.Fragment>
                {country.enabled && (
                  <Common.Space pl={[null, null, 1]} pb={[null, null, 0]}>
                    <ReactRouter.Link
                      to={`?${registerQuery}`}
                      data-testid="main-menu-register"
                    >
                      <Button stretch variant="primary" fontSize={[1, null, 3]}>
                        {translate('main-menu.register')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Space>
                )}
                <Common.Box
                  display={['none', null, 'block']}
                  pl={[null, null, 1]}
                >
                  <ReactRouter.Link
                    to={`?${loginQuery}`}
                    data-testid="main-menu-login"
                  >
                    <Button
                      stretch
                      variant="primary-outlined"
                      fontSize={[1, null, 3]}
                    >
                      {translate('main-menu.login')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Box>
              </React.Fragment>
            )}
            {authenticated && (
              <Common.Box
                display={['flex', null, null, null]}
                alignItems={['center', null, null, null]}
                pr={[0, null, '0px']}
                pt={[null, null, 0]}
              >
                <Common.Box display={['flex', 'none']} pr={[0]}>
                  <VipSegmentIcon />
                </Common.Box>
                <LoyaltyLink
                  to={`?${loyaltyQuery}`}
                  data-testid="main-menu-loyalty-program-progress"
                  onClick={() =>
                    dispatch(
                      Analytics.cashVisit({
                        eventAction: 'Topbar',
                        title: 'main-menu-loyalty-program-progress',
                        path: `${locale.slug}${location.pathname}`,
                      })
                    )
                  }
                >
                  <LoyaltyProgramMenuProgressItem />
                </LoyaltyLink>
              </Common.Box>
            )}
          </Common.Box>
          <Common.Box
            data-testid="main-menu-menu-toggler"
            display={['inline-flex', null, 'none']}
            alignItems="center"
            pl={0}
            fontSize="29px"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <Icons.Clear />
            ) : (
              <MenuNotificationBadge>
                <Icons.Menu />
              </MenuNotificationBadge>
            )}
          </Common.Box>
        </Common.Flex>
      </Common.Box>

      {/* Navigations */}
      <Menu
        variants={variants}
        animate={isMenuOpen ? 'open' : 'closed'}
        initial="closed"
      >
        <nav>
          {pendingTransactions?.length > 0 && (
            <MenuItem
              disabled={locked || !authenticated}
              icon={Icons.CoinStack}
              activeDisabled
              testId="main-menu-pending-withdrawal"
              to={`?${withdrawalQuery}`}
            >
              {translate('main-menu.pending-withdraw')}
            </MenuItem>
          )}

          {isRaffleEnabled && isRaffleLinkEnabled && isRunning ? (
            <MenuItem icon={Icons.BankNote} testId="raffle-page" to="/raffle">
              {translate('main-menu.raffle')}
            </MenuItem>
          ) : null}

          <MenuItem
            disabled={locked || !authenticated}
            exact
            icon={Icons.Favorite}
            testId="main-menu-my-games"
            to="/"
          >
            {translate('main-menu.my-games')}
          </MenuItem>

          <MenuItem
            disabled={locked}
            icon={Icons.Casino}
            testId="main-menu-casino"
            to="/casino"
          >
            {translate('main-menu.casino')}
          </MenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.LiveCasino}
            testId="main-menu-live-casino"
            to="/live-casino"
          >
            {translate('main-menu.live-casino')}
          </MenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.Sports}
            testId="main-menu-sports"
            to="/sports"
          >
            {translate('main-menu.sports')}
          </MenuItem>
          {horseRacingEnabled && (
            <MenuItem
              disabled={locked}
              icon={Icons.HorseReacing}
              testId="main-menu-horse-racing"
              to="/horse-racing"
            >
              {translate('main-menu.horse-racing')}
              {!hasPlayerSeenHorseRacing && (
                <Badge>{translate('badge.new')}</Badge>
              )}
            </MenuItem>
          )}
          {mahjongEnabled && (
            <MenuItem
              disabled={locked}
              icon={Icons.Mahjong}
              testId={'main-menu-mahjong'}
              to={`/mahjong`}
            >
              {translate('main-menu.mahjong')}
            </MenuItem>
          )}
          <MenuItem
            disabled={locked}
            icon={Icons.Star}
            testId="main-menu-vip"
            to="/vip"
          >
            <VipItemContent
              authenticated={authenticated}
              image={translate(
                `vip.perks.table.level.${Constants.ConvertedTiers[vipLevel]}.image`
              )}
            >
              {translate('main-menu.vip')}
            </VipItemContent>
          </MenuItem>
          <PromotionsMenuItem
            disabled={locked || !authenticated}
            testId="main-menu-promotion-notifications"
          >
            {translate('main-menu.promotion-notifications')}
          </PromotionsMenuItem>
          <MenuItem
            disabled={locked || !authenticated}
            icon={Icons.AllInclusive}
            testId="main-menu-loyalty-program"
            to={`?${loyaltyQuery}`}
            onClick={() =>
              dispatch(
                Analytics.cashVisit({
                  eventAction: 'Sidebar',
                  title: `${translate('main-menu.loyalty-program')}`,
                  path: `${locale.slug}${location.pathname}`,
                })
              )
            }
          >
            {translate('main-menu.loyalty-program')}
          </MenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.Moments}
            testId="main-menu-moments"
            to={
              authenticated
                ? `/moments?${personalMomentsQuery}`
                : `/moments?${globalMomentsQuery}`
            }
          >
            {translate('account.nav.me-moments')}
          </MenuItem>
          <MyAccountMenuItem
            disabled={locked || !authenticated}
            testId="main-menu-account"
          >
            {translate('main-menu.account')}
          </MyAccountMenuItem>
          <MenuItem
            disabled={locked}
            icon={Icons.Faq}
            testId="main-menu-support"
            to="/faq"
          >
            {translate('main-menu.support')}
          </MenuItem>

          {authenticated && (
            <Common.Box display="grid" gridGap={1} px={2} pt={2}>
              <ReactRouter.Link to={`?${depositQuery}`}>
                <Button stretch variant="primary" fontSize={[1, null, 3]}>
                  {translate('main-menu.deposit')}
                </Button>
              </ReactRouter.Link>
              <ReactRouter.Link to={`?${withdrawalQuery}`}>
                <Button
                  stretch
                  variant="primary-outlined"
                  fontSize={[1, null, 3]}
                >
                  {translate('main-menu.withdraw')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          )}
        </nav>
        <Common.Box
          py={1}
          pb={['50px', null, 1]}
          color="g-text"
          textAlign="center"
          fontSize={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexShrink="0"
          fontFamily="head"
          fontWeight="bold"
        >
          {authenticated ? (
            <ReactRouter.Link to="/logout" data-testid="main-menu-log-out">
              <Common.Space p={0}>
                {translate('main-menu.log-out')}
              </Common.Space>
            </ReactRouter.Link>
          ) : (
            <Common.Box display={['block', null, 'none']} pb={1}>
              {/* For returning users the primary button (the one in the
                topbar) is Login and this is a secondary one on mobile */}
              {returningPlayer && country.enabled ? (
                <ReactRouter.Link
                  to={`?${registerQuery}`}
                  data-testid="main-menu-register"
                >
                  <Button stretch variant="primary-outlined" fontSize={1}>
                    {translate('main-menu.register')}
                  </Button>
                </ReactRouter.Link>
              ) : (
                <ReactRouter.Link
                  to={`?${loginQuery}`}
                  data-testid="main-menu-login"
                >
                  <Button stretch variant="primary-outlined" fontSize={1}>
                    {translate('main-menu.login')}
                  </Button>
                </ReactRouter.Link>
              )}
            </Common.Box>
          )}
        </Common.Box>
      </Menu>
    </Wrapper>
  )
}

Herz.I18n.Loader.preload(
  [
    'main-menu.account',
    'main-menu.login',
    'main-menu.register',
    'main-menu.my-games',
    'main-menu.casino',
    'main-menu.live-casino',
    'main-menu.sports',
    'main-menu.horse-racing',
    'main-menu.mahjong',
    'main-menu.vip',
    'main-menu.promotion-notifications',
    'main-menu.loyalty-program',
    'main-menu.raffle',
    'account.nav.me-moments',
    'main-menu.support',
    'main-menu.deposit',
    'main-menu.withdraw',
    'main-menu.pending-withdraw',
    'main-menu.log-out',
    'badge.new',
    'campaign-tooltip.header',
    'campaign-tooltip.body',
  ],
  MainMenu
)

// For @loadable/components
export default MainMenu
