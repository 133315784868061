import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'

import * as Configuration from './configuration'

export function PaymentProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviderImageUrls(state.configuration)
  )

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      {R.map(
        item => (
          <Common.Box
            as="img"
            alt={item.name}
            maxHeight="35px"
            pt={1}
            pr={2}
            src={item.image.url}
            key={item.name}
          />
        ),
        paymentProviders
      )}
    </Common.Box>
  )
}
