import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as Player from './player'

export function VipSegmentIcon() {
  const level = ReactRedux.useSelector(state =>
    Player.getVipLevel(state.player)
  )
  const translate = Herz.I18n.useTranslate(
    () => [`vip.perks.table.level.${Constants.ConvertedTiers[level]}.image`],
    [level]
  )
  if (!Constants.ConvertedTiers[level]) {
    return null
  }

  return (
    <ReactRouter.Link to="/vip">
      <Common.Box
        as="img"
        width="auto"
        height="25px"
        alt={level}
        src={translate(
          `vip.perks.table.level.${Constants.ConvertedTiers[level]}.image`
        )}
      />
    </ReactRouter.Link>
  )
}
