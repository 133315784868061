import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as T from '@rushplay/theme'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { Portal } from './portal'
import { useClickOutside } from './use-click-outside'

function calculateTooltipPosition(positionType, element, tooltip) {
  switch (positionType) {
    case 'center':
      return {
        top: element.y - tooltip.height + element.height / 2 - 8,
        left: element.x + element.width / 2 - 40,
      }
    case 'right':
    default:
      return {
        top: element.y - 22,
        left: element.x + element.width + 8,
      }
  }
}

const tooltipChevronPosition = {
  center: {
    bottom: '-16px',
    left: '32px',
    transform: 'rotate(-90deg)',
  },
  right: {
    left: '-8px',
    top: '30px',
  },
}

const TooltipElement = styled('div', {
  shouldForwardProp: Common.noneOf([
    'fixed',
    'left',
    'top',
    'tooltipChevronPosition',
  ]),
})`
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  background-color: ${T.color('static-white')};
  top: ${props => `${props.top}px`};
  left: ${props => `${props.left}px`};
  color: ${T.color('static-black')};
  border-radius: 6px;

  ${css({
    maxWidth: ['250px', '310px'],
    zIndex: 'docked',
  })}

  &::before {
    content: '';
    position: absolute;
    top: ${({ tooltipChevronPosition }) => tooltipChevronPosition.top};
    bottom: ${({ tooltipChevronPosition }) => tooltipChevronPosition.bottom};
    left: ${({ tooltipChevronPosition }) => tooltipChevronPosition.left};
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 14px solid ${T.color('static-white')};
    transform: ${({ tooltipChevronPosition }) =>
      tooltipChevronPosition.transform};
  }
`

const CloseIcon = styled.div`
  ${css({
    marginTop: 0,
    marginRight: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  })}
`

export function Tooltip(props) {
  const node = React.useRef()
  const tooltipRef = React.useRef()
  const [coords, setCoords] = React.useState({ top: 0, left: 0 })

  useClickOutside(props.onClose, props.closeOnClickOutside ? tooltipRef : null)

  React.useEffect(() => {
    if (node.current) {
      const rect = node.current.getBoundingClientRect()
      const tooltipRect = tooltipRef.current.getBoundingClientRect()
      setCoords(calculateTooltipPosition(props.position, rect, tooltipRect))
    }
  }, [node.current])

  if (!props.visible) {
    return props.children
  }

  return (
    <Common.Box flex="1" ref={node}>
      {props.children}
      <Portal>
        <TooltipElement
          ref={tooltipRef}
          left={coords.left}
          top={coords.top}
          tooltipChevronPosition={R.prop(
            props.position,
            tooltipChevronPosition
          )}
          fixed={props.fixed}
        >
          <Common.Box
            flex="1"
            display="flex"
            justifyContent="flex-end"
            fontSize={[1, 3]}
          >
            <CloseIcon onClick={props.onClose}>
              <Icons.Clear />
            </CloseIcon>
          </Common.Box>
          <Common.Box px={[1, 2]} pb={[1, 2]} pt="0px">
            {props.content}
          </Common.Box>
        </TooltipElement>
      </Portal>
    </Common.Box>
  )
}

Tooltip.defaultProps = {
  position: 'right',
  onClose: () => {},
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  closeOnClickOutside: PropTypes.bool,
  content: PropTypes.node,
  fixed: PropTypes.bool,
  visible: PropTypes.bool,
  position: PropTypes.string,
  onClose: PropTypes.func,
}

export default Tooltip
