import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as FramerMotion from 'framer-motion'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as T from '@rushplay/theme'
import * as Triggers from '@rushplay/triggers'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Icons from './icons'
import * as Inventory from './inventory'
import { Badge } from './badge'
import { Heading } from './heading'
import { Tooltip } from './tooltip'
import { baseStyles } from './menu-item'
import { useSafeUpdateQuery } from './use-safe-update-query'

const DisabledLink = styled.span`
  ${baseStyles};
  color: ${T.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const MenuLink = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['highlighted']),
})`
  ${baseStyles};
  color: ${props =>
    props.highlighted ? T.color('nav-highlight') : T.color('nav-item')};
  transition: color 150ms ease-in-out;

  &:active {
    box-shadow: inset 4px 0 0 0 ${T.color('nav-highlight')};
  }
`

const variants = {
  active: {
    rotate: [0, 20, -20, 20, -20, 0],
    transition: {
      duration: 1,
      loop: Number.POSITIVE_INFINITY,
      repeatDelay: 6,
    },
  },
  inactive: {
    rotate: 0,
  },
}

export function PromotionsMenuItem(props) {
  const translate = Herz.I18n.useTranslate()
  const dispatch = ReactRedux.useDispatch()
  const shouldAnimate = ReactRedux.useSelector(
    CombinedSelectors.hasPromotionNotifications
  )
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const unseenInventoryCount = ReactRedux.useSelector(state =>
    Inventory.getUnseenItemsCount(state.inventory)
  )
  const inventoryUnseenTrigger = ReactRedux.useSelector(state =>
    Triggers.getTrigger(state.triggers, {
      kind: 'inventory',
      event: 'onboarding',
    })
  )
  const promotionsQuery = useSafeUpdateQuery({
    promotions: hasUnseenInventory ? 'inventory' : 'campaigns',
  })

  function onCloseTooltip() {
    const id = R.prop('id', inventoryUnseenTrigger)
    dispatch(Triggers.removeSelected(id))
  }

  if (props.disabled) {
    return (
      <DisabledLink data-testid={props.testId}>
        <Icons.Notifications />
        <Common.Box
          fontFamily="head"
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </Common.Box>
      </DisabledLink>
    )
  }

  return (
    <Tooltip
      fixed
      closeOnClickOutside
      visible={
        Constants.isDesktop &&
        hasUnseenInventory &&
        Boolean(inventoryUnseenTrigger)
      }
      onClose={onCloseTooltip}
      content={
        <React.Fragment>
          <Heading level={4}>{translate('campaign-tooltip.header')}</Heading>
          <Common.Box
            marginTop={1}
            lineHeight="1.5"
            fontSize={2}
            fontWeight="600"
          >
            {translate('campaign-tooltip.body')}
          </Common.Box>
        </React.Fragment>
      }
    >
      <MenuLink
        data-testid={props.testId}
        to={`?${promotionsQuery}`}
        onClick={props.onClick}
        highlighted={shouldAnimate}
      >
        <FramerMotion.motion.div
          variants={variants}
          animate={shouldAnimate ? 'active' : 'inactive'}
        >
          <Icons.Notifications />
        </FramerMotion.motion.div>
        <Common.Box
          fontFamily="head"
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </Common.Box>
        {hasUnseenInventory && <Badge>{unseenInventoryCount}</Badge>}
      </MenuLink>
    </Tooltip>
  )
}

PromotionsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}

export default PromotionsMenuItem
