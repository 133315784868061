import * as Constants from './constants'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function useMenuQueries() {
  const depositQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.DEPOSIT,
  })
  const withdrawalQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.WITHDRAWAL,
  })
  const loyaltyQuery = useSafeUpdateQuery({ 'loyalty-program': 'cashback' })
  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const registerQuery = useSafeUpdateQuery({ register: 'me' })
  const globalMomentsQuery = useSafeUpdateQuery({ scope: 'global' })
  const personalMomentsQuery = useSafeUpdateQuery({ scope: 'personal' })

  return {
    depositQuery,
    globalMomentsQuery,
    loginQuery,
    loyaltyQuery,
    personalMomentsQuery,
    registerQuery,
    withdrawalQuery,
  }
}
