import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'

import * as Configuration from './configuration'

export function GameProviderImages() {
  const gameProviders = ReactRedux.useSelector(state =>
    Configuration.getGameProviderImageUrls(state.configuration)
  )

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
    >
      {R.map(
        item => (
          <LazyLoad once height="34px" key={item.name} offset={100}>
            <Common.Box as="img" alt={item.name} p={2} src={item.image.url} />
          </LazyLoad>
        ),
        gameProviders
      )}
    </Common.Box>
  )
}
