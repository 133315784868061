import * as React from 'react'
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export function Portal(props) {
  const root = document.body
  const el = document.createElement('div')

  React.useEffect(() => {
    root.appendChild(el)

    return () => root.removeChild(el)
  }, [el, root])
  return ReactDOM.createPortal(props.children, el)
}

Portal.propTypes = {
  children: PropTypes.element.isRequired,
}
